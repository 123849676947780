import * as React from 'react';
import {useState} from 'react';
import {Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Stack, TextField} from '@mui/material';
import {CopyButton} from "../../../renderers/CopyButton";
import {XhrHttpModule} from "@intuitionrobotics/thunderstorm/frontend";
import {HttpMethod} from "@intuitionrobotics/thunderstorm";
import {ApiSetLocalSevers} from '@app-sp/app-shared/api';
import {SimpleLoader} from "@components/SimpleLoader";
import {EnvironmentModule} from "@modules/EnvironmentModule";

export const SetLocalServersButton = ({unitId}: { unitId: string }) => {
    if (EnvironmentModule.getEnvName() === "prod")
        return null;

    const [showModal, setShowModal] = useState(false);
    const [localIp, setLocalIp] = useState('');
    const [serverApi, setServerApi] = useState(false);
    const [speechServer, setSpeechServer] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSetLocalServers = async () => {
        if (!serverApi && !speechServer) {
            alert("Please select at least one server.");
            return;
        }

        const targets = [];
        if (serverApi) targets.push("api");
        if (speechServer) targets.push("speech");
        setLoading(true);
        await XhrHttpModule.createRequest<ApiSetLocalSevers>(HttpMethod.POST, `set-local-servers`)
            .setRelativeUrl(`/v1/unit/set-local`)
            .setJsonBody({unitId, localIp, targets})
            .executeSync().catch(() => setLoading(false));

        backToInitialState();
    };

    const backToInitialState = () => {
        setShowModal(false);
        setLocalIp('');
        setServerApi(false);
        setSpeechServer(false);
        setLoading(false);
    }

    return (
        <div>
            <Button
                className={"unit_monitoring_btn"}
                size="small"
                fullWidth
                variant="outlined"
                style={{
                    marginTop: "10px",
                    textTransform: "none"
                }}
                onClick={() => setShowModal(true)}
            >
                Set Local Servers
            </Button>
            <Dialog open={showModal} onClose={() => backToInitialState()} fullWidth maxWidth="sm">
                <DialogTitle>Set Local Servers</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <TextField
                            label="Local IP"
                            helperText={<span>Run ipconfig getifaddr en0<CopyButton obj={"ipconfig getifaddr en0 | pbcopy"}/> to retrieve the local IP.</span>}
                            value={localIp}
                            onChange={(e) => setLocalIp(e.target.value)}
                            fullWidth
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={serverApi}
                                    onChange={(e) => setServerApi(e.target.checked)}
                                />
                            }
                            label="Server API"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={speechServer}
                                    onChange={(e) => setSpeechServer(e.target.checked)}
                                />
                            }
                            label="Speech Server"
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => backToInitialState()}>Cancel</Button>
                    <Button disabled={!serverApi && !speechServer} variant="contained" onClick={handleSetLocalServers}>
                        Set Local Servers
                    </Button>
                </DialogActions>
                {loading && <SimpleLoader absolute={true}/>}
            </Dialog>
        </div>
    );
};
